<template>
  <div class="image-grid-container">
    <div class="sort-options">
      <label for="sort-method">Sort by:</label>
      <select id="sort-method" v-model="selectedSort" @change="sortItems">
        <option value="viewerCount">Viewer Count</option>
        <option value="startTime">Start Time</option>
        <option value="random">Random</option>
      </select>

      <div v-if="selectedSort !== 'random'" class="sort-order">
        <label>Order:</label>
        <label>
          <input type="radio" value="descending" v-model="sortOrder" @change="sortItems" />
          Descending
        </label>
        <label>
          <input type="radio" value="ascending" v-model="sortOrder" @change="sortItems" />
          Ascending
        </label>
      </div>
    </div>
    
    <div class="image-grid">
      <div v-for="item in sortedItems" :key="item.platformID" :class="{'faded': !item.isLive}" class="grid-item">
        <a :href="`https://kick.com/${item.streamerName}`" target="_blank" rel="noopener noreferrer">
          <img :src="`/StreamThumbnails/${item.thumbnailFileName}`" :alt="item.sessionTitle" />
        </a>
        <div class="info">
          <h3 @click.stop="showPopup(item)" class="streamer-name">{{ item.streamerName }}</h3>
          <h2 class="category">{{ item.category }}</h2>
          <p>{{ item.sessionTitle }}</p>
          <p v-if="item.isLive" class="live-status">Live Now! - Live for {{ calculateLiveDuration(item.startTime) }}</p>
          <p v-else>Offline - Last seen {{ calculateLastSeen(item.startTime, item.duration) }} ago</p>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div v-if="popupVisible" class="popup">
      <button class="popup-close" @click="hidePopup">&times;</button>
      <img :src="`/StreamThumbnails/${popupItem.thumbnailFileName}`" class="popup-thumbnail" />
      <div class="popup-content">
        <h2>{{ popupItem.streamerName }}</h2>
        <p>{{ popupItem.sessionTitle }}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ImageGrid',
  data() {
    return {
      items: [],
      selectedSort: 'viewerCount',
      sortOrder: 'descending',
      popupVisible: false,
      popupItem: null,
      popupWidth: window.innerWidth / 2,
      popupHeight: window.innerHeight / 2,
    }
  },
  computed: {
    sortedItems() {
      let items = [...this.items];

      if (this.selectedSort === 'random') {
        const liveItems = items.filter(item => item.isLive);
        const offlineItems = items.filter(item => !item.isLive);

        liveItems.sort(() => Math.random() - 0.5);
        offlineItems.sort(() => Math.random() - 0.5);

        return [...liveItems, ...offlineItems];
      } else {
        items.sort((a, b) => {
          if (b.isLive !== a.isLive) return b.isLive - a.isLive;

          const valueA = this.selectedSort === 'viewerCount' ? a.viewerCount : new Date(a.startTime);
          const valueB = this.selectedSort === 'viewerCount' ? b.viewerCount : new Date(b.startTime);

          return this.sortOrder === 'ascending' ? valueA - valueB : valueB - valueA;
        });

        return items;
      }
    }
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch('LsnStreamers.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.items = data;
        this.sortItems(); // Sort the items initially based on the default sort method
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    sortItems() {
      if (this.selectedSort === 'random') {
        const liveItems = this.items.filter(item => item.isLive);
        const offlineItems = this.items.filter(item => !item.isLive);

        liveItems.sort(() => Math.random() - 0.5);
        offlineItems.sort(() => Math.random() - 0.5);

        this.items = [...liveItems, ...offlineItems];
      } else {
        this.items.sort((a, b) => {
          if (b.isLive !== a.isLive) return b.isLive - a.isLive;

          const valueA = this.selectedSort === 'viewerCount' ? a.viewerCount : new Date(a.startTime);
          const valueB = this.selectedSort === 'viewerCount' ? b.viewerCount : new Date(b.startTime);

          return this.sortOrder === 'ascending' ? valueA - valueB : valueB - valueA;
        });
      }
    },
    showPopup(item) {
      this.popupItem = item;
      this.popupVisible = true;
    },
    hidePopup() {
      this.popupVisible = false;
      this.popupItem = null;
    },
    calculateLiveDuration(startTime) {
      const start = new Date(startTime);
      const now = new Date();
      const durationMs = now.getTime() - start.getTime();

      const hours = Math.floor(durationMs / 3600000);
      const minutes = Math.floor((durationMs % 3600000) / 60000);

      if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}`;
      } else {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }
    },
    calculateLastSeen(startTime, duration) {
      const start = new Date(startTime);
      const endOfLastStream = start.getTime() + duration;
      const now = new Date();
      const durationMs = now.getTime() - endOfLastStream;

      const days = Math.floor(durationMs / 86400000);
      const hours = Math.floor((durationMs % 86400000) / 3600000);
      const minutes = Math.floor((durationMs % 3600000) / 60000);

      if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''}`;
      } else {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }
    },
    updatePopupDimensions() {
      this.popupWidth = window.innerWidth / 2;
      this.popupHeight = window.innerHeight / 2;
    }
  },
  mounted() {
    this.fetchData();
    window.addEventListener('resize', this.updatePopupDimensions);
    this.updatePopupDimensions();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updatePopupDimensions);
  }
}
</script>


<style scoped>
.image-grid-container {
  padding: 16px;
}

.sort-options {
  margin-bottom: 16px;
  color: white; /* White text for the sort options */
}

.sort-order {
  margin-top: 8px;
  display: flex;
  gap: 10px; /* Add some space between the radio buttons */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  background-color: black;
  padding: 16px;
  color: gray;
}

.grid-item {
  border: 1px solid #333; /* Slightly lighter border for better contrast */
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: #222; /* Darker background for individual items */
}

.grid-item img {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease, border-color 0.3s ease; /* Add border-color transition */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Faint white border */
}

.faded img {
  filter: grayscale(100%) brightness(50%);
}

.info {
  padding: 2px;
}

.info h3 {
  color: lightgray; /* Color for session title */
  cursor: pointer; /* Change cursor to pointer for clickable items */
  margin: 4px 4px; /* Reducing the top and bottom margins to 4px */
}

.info p {
  color: gray; /* Color for general text */
  margin: 4px 4px; /* Reducing the top and bottom margins to 4px */
  padding: 4px; /* Remove any padding if present */
}

.category {
  font-size: 0.85em; /* Slightly smaller font size */
  color: lightgray; /* Optional: you can style it to be more subtle */
}

.live-status {
  color: white; /* Highlight color for live status */
}

.viewer-count {
  color: lightgray; /* Color for viewer count text */
}

a {
  text-decoration: none;
  color: inherit;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Faint border */
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.popup-thumbnail {
  max-width: 100%;
  max-height: 50%;
  margin-bottom: 16px;
}

.popup-content {
  text-align: center;
}
</style>
