<template>
  <div id="app">
    <header class="page-header">
      <h1>The Legitimate Streamer Network</h1>
      <p>Explore live streams with real people!</p>
    </header>
    <ImageGrid />
  </div>
</template>

<style>
html, body {
  background-color: black;
  margin: 0;
  padding: 0;
  color: gray; /* Set the default text color for the entire page */
  font-family: Arial, sans-serif; /* Optional: set a default font for the page */
}

#app {
  text-align: center;
}

.page-header {
  padding: 20px;
  background-color: #111; /* Dark background for the header */
  color: white; /* White text for the header */
}

.page-header h1 {
  margin: 0;
  font-size: 2.5em;
}

.page-header p {
  margin: 0;
  font-size: 1.2em;
}
</style>

<script>
import ImageGrid from './components/ImageGrid.vue'

export default {
  name: 'App',
  components: {
    ImageGrid
  }
}
</script>
